import { OptionModel } from "@models/global";

export const CMS_NAME = "1hour.dev";
export const CMS_URL = "https://www.1hour.dev";
export const HOME_OG_IMAGE_URL = "https://www.1hour.dev/assets/images/logo.svg";
export const POST_DEFAULT_IMAGE =
  "https://public.blob.vercel-storage.com/eEZHAoPTOBSYGBE3/hxfcV5V-eInX3jbVUhjAt1suB7zB88uGd1j20b.png";
export const DEFAULT_BANNER_IMAGE =
  "https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2710&amp;q=80";
export let phoneRegex =
  /^[+]?\(?([0-9]{0,3})?\)?[-. ]?([0-9]{1,3})?[-. ]?([0-9]{1,3})[-. ]?([0-9]{1,5})$/;
export let emailRegex =
  /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export let dateFormat = "DD/MM/YYYY";
export let dateTimeFormat = "DD/MM/YYYY hh:mm";
export let dateTimeSecondFormat = "DD/MM/YYYY hh:mm:ss";
export let monthFormat = "MM/YYYY";
export let yearFormat = "YYYY";
export let timeFormat = "hh:mm";

export const AppConsts = {
  title: "News",
  appProjectType: {
    property: "property",
    news: "news",
    foods: "foods",
  },
  authorization: {
    encryptedAuthTokenName: "enc_auth_token",
    currentUser: "current_user",
  },
  align: {
    right: "right" as const,
    left: "left" as const,
    center: "center" as const,
  },
  dataType: {
    string: "string" as const,
    number: "number" as const,
    boolean: "boolean" as const,
    method: "method" as const,
    regexp: "regexp" as const,
    integer: "integer" as const,
    float: "float" as const,
    object: "object" as const,
    enum: "enum" as const,
    date: "date" as const,
    url: "url" as const,
    hex: "hex" as const,
    email: "email" as const,
  },
  formVerticalLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 24 },
      xxl: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 },
      lg: { span: 24 },
      xl: { span: 24 },
      xxl: { span: 24 },
    },
  },
  formHorizontalLayout: {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 12 },
      md: { span: 12 },
      lg: { span: 6 },
      xl: { span: 6 },
      xxl: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 12 },
      md: { span: 12 },
      lg: { span: 18 },
      xl: { span: 18 },
      xxl: { span: 20 },
    },
  },
  validate: {
    maxNumber: 999999999999,
  },
  dataTable: {
    pageSize: 20,
  },
  cookiesKey: {
    requestContact: "request-contact",
  },
};

export const AIPOST_TONE: OptionModel[] = [
  { value: 1, name: "Tích cực" },
  { value: 2, name: "Tiêu cực" },
];

export const POST_CATEGORIES = ["Design", "Technical", "Art"];
export const POST_STATUS = {
  new: "New",
  published: "Published",
  deleted: "Deleted",
};

export const CAMPAIGN_STATUS = {
  active: "active",
  publish: "publish",
  finish: "finish",
  inactive: "inactive",
};

export const CATEGORY_STATUS = {
  active: "active",
  inactive: "inactive",
};

export const REQUEST_CONTACT_STATUS = {
  active: "active",
  inprogress: "inprogress",
  finish: "finish",
  inactive: "inactive",
};

export const LIST_STATUS = {
  active: "active",
  inactive: "inactive",
};

export const PRODUCT_TYPE = {
  drink: "drink",
  food: "food",
  eProduct: "e-product",
};

export const productTypes = [
  { label: "Drink", value: PRODUCT_TYPE.drink },
  { label: "Food", value: PRODUCT_TYPE.food },
  { label: "E-Product", value: PRODUCT_TYPE.eProduct },
];

export const PRODUCT_SIZES = ["small", "medium", "large"];

export const PRODUCT_ICE_LEVELS = [
  { label: "No ice", value: "no_ice" },
  { label: "Less ice", value: "less_ice" },
  { label: "Regular", value: "regular_ice" },
];
export const PRODUCT_SWEETNESS = [
  { label: "No sweetness", value: "no_sweetness" },
  { label: "Less sweet", value: "less_sweet" },
  { label: "Regular", value: "regular_sweet" },
  { label: "Extra", value: "extra_sweet" },
];

export const PRODUCT_SPICINESS = [
  { label: "Mild", value: "mild" },
  { label: "Medium", value: "medium" },
  { label: "Hot", value: "hot" },
];

// Layout constant
export const themeByEvent = {
  events: {
    default: "default",
    xmasSanta: "xmas-santa",
    xmasSantaLaugh: "xmas-santa-laugh",
    xmasHouse: "xmas-house",
    xmasNight: "xmas-night",
    flowers: "flowers",
    snowFlake: "snow-flake",
  },
};

export const AppConfiguration = {
  appBaseUrl: "",
  remoteServiceBaseUrl: "",
  appLayoutConfig: {
    loader: { type: themeByEvent.events.flowers, delayTime: 300000 },
  } as any,
};

export const socialType = {
  facebook: "facebook",
  twitter: "twitter",
};

export const LINK = {
  TWITTER: "https://x.com/dfgefgef11",
  GITHUB: "https://github.com/pqoqubbw/icons",
  LUCIDE: "https://lucide.dev",
  MOTION: "https://motion.dev",
};

// EDITOR
export const INIT_EDITOR_CONFIG = (
  onFindImage: any,
  onFindVideo: any,
  onWriteMore: any,
) => {
  return {
    height: 800,
    menubar: false,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
      "undo redo | formatselect | " +
      "bold italic backcolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help",
    content_style:
      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    setup: function (editor: any) {
      editor.ui.registry.addButton("btnFindImage", {
        text: "Find Image",
        onAction: onFindImage,
      });
      editor.ui.registry.addButton("btnFindVideo", {
        text: "Find Video",
        onAction: onFindVideo,
      });
      editor.ui.registry.addButton("btnWriteMore", {
        text: "Write More",
        onAction: onWriteMore,
      });
      editor.ui.registry.addContextToolbar("textselection", {
        predicate: function () {
          return !editor.selection.isCollapsed();
        },
        items: "btnFindImage btnFindVideo btnWriteMore",
        position: "selection",
        scope: "node",
      });
    },
  };
};

export const MASTER_DATA_GROUP = {
  department: "DEPARTMENT",
};

export const PERMISSIONS = {
  user: {
    read: "User.Read",
    create: "User.Create",
    update: "User.Update",
    delete: "User.Delete",
  },
  salaryMaster: {
    read: "SalaryMaster.Read",
    create: "SalaryMaster.Create",
    update: "SalaryMaster.Update",
    delete: "SalaryMaster.Delete",
  },
  role: {
    read: "Role.Read",
    create: "Role.Create",
    update: "Role.Update",
    delete: "Role.Delete",
  },
  permission: {
    read: "Permission.Read",
    create: "Permission.Create",
    update: "Permission.Update",
    delete: "Permission.Delete",
  },
  masterData: {
    read: "MasterData.Read",
    create: "MasterData.Create",
    update: "MasterData.Update",
    delete: "MasterData.Delete",
    generateLocation: "MasterData.GenerateLocation",
  },
  setting: {
    read: "Setting.Read",
    create: "Setting.Create",
    update: "Setting.Update",
    delete: "Setting.Delete",
  },
  salary: {
    read: "Salary.Read",
    create: "Salary.Create",
    update: "Salary.Update",
    delete: "Salary.Delete",
  },
  revenue: {
    read: "Revenue.Read",
    create: "Revenue.Create",
    update: "Revenue.Update",
    delete: "Revenue.Delete",
  },
  campaign: {
    read: "Campaign.Read",
    create: "Campaign.Create",
    update: "Campaign.Update",
    delete: "Campaign.Delete",
  },
  project: {
    read: "Project.Read",
    create: "Project.Create",
    update: "Project.Update",
    delete: "Project.Delete",
  },
  property: {
    read: "Property.Read",
    create: "Property.Create",
    update: "Property.Update",
    delete: "Property.Delete",
  },
  masterPost: {
    read: "MasterPost.Read",
    create: "MasterPost.Create",
    update: "MasterPost.Update",
    delete: "MasterPost.Delete",
  },
  post: {
    read: "Post.Read",
    create: "Post.Create",
    update: "Post.Update",
    updateSite: "Post.UpdateSite",
    delete: "Post.Delete",
  },
  menu: {
    read: "Post.Read",
    create: "Post.Create",
    update: "Post.Update",
    updateSite: "Post.UpdateSite",
    delete: "Post.Delete",
  },
  category: {
    read: "Category.Read",
    create: "Category.Create",
    update: "Category.Update",
    delete: "Category.Delete",
  },
  organization: {
    read: "Organization.Read",
    create: "Organization.Create",
    update: "Organization.Update",
    delete: "Organization.Delete",
  },
  site: {
    read: "Site.Read",
    create: "Site.Create",
    update: "Site.Update",
    delete: "Site.Delete",
  },
  request: {
    read: "RequestContact.Read",
    create: "RequestContact.Create",
    update: "RequestContact.Update",
    delete: "RequestContact.Delete",
  },
  locationGroup: {
    read: "MasterData.Read",
    create: "MasterData.Create",
    update: "MasterData.Update",
    delete: "MasterData.Delete",
  },
  cashAdvance: {
    read: "",
    create: "",
    update: "",
    delete: "",
  },
  receipt: {
    read: "",
    create: "",
    update: "",
    delete: "",
  },
  order: {
    read: "",
    create: "",
    update: "",
    delete: "",
  },
};

/// FINANCE Constant
export const RECEIPT_TYPE = {
  deposit: "DEPOSIT",
  withdraw: "WITHDRAW",
  payment: "PAYMENT",
  refund: "REFUND",
  redeem: "REDEEM",
};

export const receiptTypeOptions = [
  new OptionModel(RECEIPT_TYPE.deposit, "Deposit"),
  new OptionModel(RECEIPT_TYPE.withdraw, "Withdraw"),
  new OptionModel(RECEIPT_TYPE.payment, "Payment"),
  new OptionModel(RECEIPT_TYPE.refund, "Refund"),
];

export const PAYMENT_METHOD = {
  cash: "CASH",
  bank: "BANK",
  cashAdvance: "CA",
};

export const paymentMethodOptions = [
  new OptionModel(PAYMENT_METHOD.cash, "Cash", undefined, undefined, true),
  new OptionModel(PAYMENT_METHOD.bank, "Bank", undefined, undefined, true),
  new OptionModel(PAYMENT_METHOD.cashAdvance, "Cash Advance"),
];

export const PHOTOS: string[] = [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];
